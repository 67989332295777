.footer-content {
  position: fixed;
  bottom: 0;
  right: 10px;
  display: flex;
  justify-content: center;
  
  .footer-content__text {
    display: flex;
    justify-content: space-around;
    gap: 1em;
    color: lightgray;
  }
}