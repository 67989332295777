// screen sizes
$phone-width: 480px;
$tablet-width: 768px;
$tablet-landscape: 992px;
$desktop-width: 1260px;
$fullhd-width: 1920px;

$navi-collapse-width: 850px;
// breakpoints

// Use following syntax when you need a specific range:
//
// @include from-phone() {
//   @include to-tablet() {
//     .your-class {}
//   }
// }

@mixin collapsed-navi() {
  @media (max-width: $navi-collapse-width) {
    @content;
  }
}

@mixin normal-navi() {
  @media (min-width: $navi-collapse-width + 1) {
    @content;
  }
}

@mixin to-phone() {
  @media (max-width: $phone-width) {
    @content;
  }
}

@mixin from-phone() {
  @media (min-width: $phone-width + 1) {
    @content;
  }
}

@mixin to-tablet() {
  @media (max-width: $tablet-width) {
    @content;
  }
}

@mixin from-tablet() {
  @media (min-width: $tablet-width + 1) {
    @content;
  }
}

@mixin to-tablet-landscape() {
  @media (max-width: $tablet-landscape) {
    @content;
  }
}

@mixin from-tablet-landscape() {
  @media (min-width: $tablet-landscape + 1) {
    @content;
  }
}

@mixin to-desktop() {
  @media (max-width: $desktop-width) {
    @content;
  }
}

@mixin from-desktop() {
  @media (min-width: $desktop-width + 1) {
    @content;
  }
}

@mixin to-fullhd() {
  @media (max-width: $fullhd-width) {
    @content;
  }
}

@mixin from-fullhd() {
  @media (min-width: $fullhd-width + 1) {
    @content;
  }
}

// 16:9 aspect ration
@mixin keep-16to9($width) {
  padding-bottom: calc($width / 16 * 9);
  width: $width;
}

// 2.39:1 widescreen cinema standard
@mixin keep-wide($width) {
  padding-bottom: $width / 2.39;
  width: $width;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin bottom-shadow() {
  box-shadow: 0 3px 4px rgba(0, 0, 0, .1);
}

@mixin shadow($blurRadius) {
  -webkit-box-shadow: 0px 0px $blurRadius 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px $blurRadius 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px $blurRadius 0px rgba(0,0,0,0.75);
}

@mixin inner-shadow($blurRadius) {
  -webkit-box-shadow: inset 0px 0px $blurRadius 0px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 0px $blurRadius 0px rgba(0,0,0,0.75);
  box-shadow: inset 0px 0px $blurRadius 0px rgba(0,0,0,0.75);
}

@mixin blur($stdev) {
  // See http://stackoverflow.com/questions/15803122/filter-blur1px-doesnt-work-in-firefox-ie-and-opera
  // http://demosthenes.info/blog/534/Cross-browser-Image-Blur-with-CSS
  -webkit-filter: blur($stdev);
     -moz-filter: blur($stdev);
       -o-filter: blur($stdev);
      -ms-filter: blur($stdev);
          filter: blur($stdev);
          filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='$stdev');
}

@mixin placeholder-color($color) {
  // for some reason, this doesn't work when selectors are combined into one, hence the repetition
  input[type=text],
  input[type=password] {
    &::-webkit-input-placeholder {
      color: $color;
    }

    &:-moz-placeholder {
      color: $color;
    }

    &::-moz-placeholder {
      color: $color;
    }

    &:-ms-input-placeholder {
      color: $color;
    }
  }
}

@mixin linear-gradient( $start: #000, $from: 0%, $stop: #fff, $to: 100% ) {
  background: $start;
  background: -webkit-gradient(linear, left top, left bottom, from(ie-hex-str($start)), to(ie-hex-str($stop)));
  background: -moz-linear-gradient(center top, $start $from, $stop $to);
  background: -moz-gradient(center top, $start $from, $stop $to);
  background: -webkit-linear-gradient(top, $start $from,$stop $to);
  background: -o-linear-gradient(top, $start $from,$stop $to);
  background: -ms-linear-gradient(top, $start $from,$stop $to);
  background: linear-gradient(to bottom, $start $from,$stop $to);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($start)}', endColorstr='#{ie-hex-str($stop)}',GradientType=0 );
}

@mixin linear-gradient-horizontal( $start: #000, $from: 0%, $stop: #fff, $to: 100% ) {
  background: $start;
  background: -webkit-gradient(linear, left right, left bottom, from(ie-hex-str($start)), to(ie-hex-str($stop)));
  background: -moz-linear-gradient(center right, $start $from, $stop $to);
  background: -moz-gradient(center right, $start $from, $stop $to);
  background: -webkit-linear-gradient(right, $start $from, $stop $to);
  background: -o-linear-gradient(right, $start $from, $stop $to);
  background: -ms-linear-gradient(right, $start $from, $stop $to);
  background: linear-gradient(to right, $start $from, $stop $to);
}

@mixin sprite($sprite, $offset-x: 0, $offset-y: 0) {
  background-image: $iconsUrl;
  background-position: nth(sprite-position($icons, $sprite), 1) + $offset-x nth(sprite-position($icons, $sprite), 2) + $offset-y;
  background-repeat: no-repeat;

  @media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3/2), (min--moz-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 144dppx) {
    background-image: $icons2xUrl;
    background-size: (image-width(sprite-path($icons-2x)) / 2) (image-height(sprite-path($icons-2x)) / 2);
    background-position: round(nth(sprite-position($icons-2x, $sprite), 1) / 2) + $offset-x round(nth(sprite-position($icons-2x, $sprite), 2) / 2) + $offset-y;
  }
}

@mixin content-wrap() {
  margin: 0 auto;
  position: relative;
  width: 95%;

  @include from-tablet-landscape() {
    width: 900px;
  }

  @include from-desktop() {
    width: 1260px;
  }
}

@mixin toggle-button($color: $white) {
  @include border-radius(4px);
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  color: $color;
  cursor: pointer;
  float: right;
  padding: 10px;
  position: relative;

  &:focus {
    outline: 0;
  }

  .icon-bar {
    background-color: $color;
    border-radius: 1px;
    display: block;
    height: 2px;
    width: 22px;
  }

  .icon-bar + .icon-bar {
    margin-top: 4px;
  }
}

@mixin icon-live($color: #fff) {
  @include border-radius(15px);
  border: 2px solid $color;
  color: $color;
  display: inline-block;
  float: left;
  padding: 5px 10px 2px;
  position: relative;

  @include from-tablet() {
    @include border-radius(25px);
    padding: 10px 20px 7px;
  }

  &:before {
    content: 'LIVE';
    display: inline-block;
    float: left;
    font-family: 'FyranSans';
    font-size: 15px;
  }
}

@mixin logo-katsomo-common() {
  display: inline-block;

  &:before,
  &:after {
    content: ' ';
    display: inline-block;
    float: left;
  }
}

@mixin logo-katsomo-xsmall {
  @include logo-katsomo-common();
  height: 22px;
  width: 137px;

  &:before {
    @include sprite(mtv-xsmall-gray);
    height: 22px;
    width: 21px;
  }

  &:after {
    @include sprite(katsomo-xsmall-green);
    height: 19px;
    margin-left: 2px;
    margin-top: 1px;
    width: 111px;
  }
}

@mixin logo-katsomo-small {
  @include logo-katsomo-common();
  height: 26px;
  width: 162px;

  &:before {
    @include sprite(mtv-small-gray);
    height: 26px;
    width: 26px;
  }

  &:after {
    @include sprite(katsomo-small-green);
    height: 23px;
    margin-left: 3px;
    margin-top: 2px;
    width: 133px;
  }
}

@mixin logo-katsomo {
  @include logo-katsomo-common();
  height: 33px;
  width: 208px;

  &:before {
    @include sprite(mtv-gray);
    height: 33px;
    width: 33px;
  }

  &:after {
    @include sprite(katsomo-green);
    height: 29px;
    margin-left: 4px;
    margin-top: 2px;
    width: 171px;
  }
}

@mixin logo-katsomo-large {
  @include logo-katsomo-common();
  height: 50px;
  width: 320px;

  &:before {
    @include sprite(mtv-large-gray);
    height: 50px;
    width: 50px;
  }

  &:after {
    @include sprite(katsomo-large-green);
    height: 44px;
    margin-left: 5px;
    margin-top: 3px;
    width: 265px;
  }
}

@mixin text-bold() {
  font-family: 'FyranSans', Arial, sans-serif;
  font-weight: 700;
}

@mixin text-ellipsis($width: 100%) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: $width;
}

@mixin gold-box($top: -45px, $left: 73%, $angle: -15deg) {
  position: relative;
  overflow: hidden;
  background: $gold;

  &:after {
    @include linear-gradient(#9f854f, 0%, $gold, 100%);
    content: '';
    display: block;
    height: 100%;
    left: $left;
    position: absolute;
    top: $top;
    transform: rotate($angle);
    width: 100%;
    z-index: 1;
  }

  * {
    position: relative;
    z-index: 2;
  }
}

@mixin clearfix() {
  &:before, &:after {
    box-sizing: border-box;
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin cover-bg() {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
