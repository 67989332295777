@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.header-content {
  width: 100%;
  color: white;
  padding: 0;
  margin: 0;
  background-color: $gray-lighter;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 200px;
  z-index: 1;
  &.nav-only {
    min-height: 70px;
  }
}

.header-content__navi {
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  position: relative;
  background-color: $red;
}

.header-links-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.logout {
  color: black;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.5em;
  padding: 8px;
}

.header-content__site-name {
  position: absolute;
  top: 0.5em;
  padding-left: 0.2em;
}

.header-content__text {
  font-size: 1.3em;
  padding: 2em;
  z-index: 3;

  h1 {
    font-size: 1.3em;
  }
}

.header-content__text__content {
  font-size: 0.7em;
  color: $gray-light;
}

.header-content__text__title {
  font-size: 1.3em;
  font-weight: 700;
  color: black;
}

.header-content__liigalink {
  color: white;
  text-decoration: none;
  float: right;
  margin-top: 0.7em;
  margin-right: 0.5em;
}

.video-card-list__search-results-title {
  text-transform: uppercase;
  font-weight: bold;
  z-index: 3;
}

// hide header when player is running
/*
.playeractive {
  .header-content__text,
  .header-content__navi {
    display: none;
  }
}

.playeractive .header-content {
  min-height: 0;
}
*/

.katsomo-logo {
  height: 35px;
  width: 110px;
  z-index: 3;

  display: inline-block;
  margin-top: 0.5em;
  margin-left: 0.5em;

  &:before {
    background-image: url("../../images/icons-2x/mtv2022-small.png");
    height: 35px;
    width: 35px;
    background-size: 35px 35px;

    content: " ";
    display: inline-block;
  }
}
