@import 'variables';
@import 'mixins';
@import 'typography';

body {
  background-color: $gray-lighter;
  padding: 0;
  margin: 0;
  font-family: 'FyranSans',Arial,Helvetica,sans-serif !important;
  font-size: 1em;
  line-height: 1.428571429;
}

.centered {
  text-align: center;
}

.page-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

/* The alert message box */
.alert {
  padding: 20px;
  background-color: #ff9800;
  color: white;
  margin: auto;
  margin-bottom: 15px;
  max-width: 960px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

.mt-1 {
  margin-top: 1em;
}