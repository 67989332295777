.videoplayer-wrapper {
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .player {
    width: 100%;
    max-width: 960px;
    height: 100%;
    margin: 0 auto;

    &.maximized {
      max-width: unset;
    }
  
    .logo,
    .program-text,
    .program-duration {
      display: none;
    }
  
    .program-info {
      height: 100%;
      justify-content: center;
      padding-bottom: 0;
    }
  }
  
}