@font-face {
  font-family: "QuattroNews";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: local("QuattroNews Regular"),local("QuattroNews-Regular"),url("https://st.mtvuutiset.fi/resource/fonts/QuattroNews-Regular.woff2") format("woff2")
}

@font-face {
  font-family: "QuattroNews";
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: local("QuattroNews Bold"),local("QuattroNews-Bold"),url("https://st.mtvuutiset.fi/resource/fonts/QuattroNews-Bold.woff2") format("woff2")
}

body {
  font-family: 'QuattroNews',Arial,Helvetica,sans-serif !important;
  font-size: 1em;
  line-height: 1.428571429; // 20/14

  @include to-tablet() {
    font-size: $font-size-base;
  }
}

// TODO: this needs rethinking
span, p, a {
  font-size: $font-size-base;

  span, p, a {
    font-size: 1em;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: $gray-light;
  }
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;

  small,
  .small {
    font-size: 65%;
  }
}

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;

  small,
  .small {
    font-size: 75%;
  }
}

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

.text-large  { font-size: $font-size-large; }
.text-larger { font-size: $font-size-larger; }

p {
  margin: 0 0 10px;
}

small,
.small {
  font-size: floor(calc(100% * $font-size-small / $font-size-base));
}

.text-bold {
  @include text-bold();
}

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

// Transformation
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

.text-ellipsis       { @include text-ellipsis(); }

// Contextual colors
.text-muted {
  color: #97999b;
}

.text-white {
  color: $white;
}

.text-dark {
  color: $gray;
}

.text-green {
  color: $green;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  ul, ol {
    margin-bottom: 0;
  }
}

th {
  font-weight: 500;
}
