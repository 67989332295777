// SCSS variables
// Disclaimer: naming is just for historical reasons

// Compass sprites (generated)
/* TODO add sprite support / SVG icons
$icons:      sprite-map("icons/*.png", $layout: smart);
$icons-2x:   sprite-map("icons-2x/*.png", $layout: smart);
$iconsUrl:   sprite-url($icons);
$icons2xUrl: sprite-url($icons-2x);
*/

// colors
$gold:         #94753a;
$green:        #84bd00;
$orange:       #ffa200;
$red:          #e81e2e;
$blue:         #0877cc;
$white:        #fff;

$gray-darker:  lighten(#000, 13.5%); // #222
$gray-dark:    lighten(#000, 19%);   // #303030
$gray:         lighten(#000, 29.7%); // #4c4c4c
$gray-icon:    #63666a;
$gray-light:   #757678;
$gray-navi:    #dad8d6;
$gray-semi-light: #a9abab;
$gray-semi-lighter: #dedede;
$gray-lighter: lighten(#000, 93.5%); // #eee
$gray-background: #f6f6f6;
$gray-transparent: rgba(150, 150, 150, .6);

$blue-lighter:  #e8f2fa;

// typography
$font-size-larger:   1.250em;  // ~20px
$font-size-large:    1.125em;  // ~18px
$font-size-base:     .875em;   // ~14px
$font-size-small:    .75em;    // ~12px
$font-size-smaller:  .625em;   // ~10px
$font-size-h1:       2.250em;  // ~36px
$font-size-h2:       1.875em;  // ~30px
$font-size-h3:       1.5em;    // ~24px
$font-size-h4:       $font-size-large;
$font-size-h5:       $font-size-base;
$font-size-h6:       $font-size-small;
